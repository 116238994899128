import { useDispatch, useSelector } from "react-redux";
import { updateSort } from "../../redux/cvs/cvsSlice";
import {
  selectCurrentSearchId,
  selectLoadedFilters,
  selectSearchFilters,
  selectSortWages,
  selectUser,
} from "../../redux/selectors";
import { createNewSearch, updateSearch } from "../../redux/cvs/cvsOperations";
import { areEqual, camelToSnake } from "../../utils";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import css from "./Sort.module.css";

export const Sort = () => {
  const dispatch = useDispatch();
  const sortKeys = [
    "completeness",
    "salary",
    "seniority",
    "english",
    "keywords",
  ];
  const sortWages = useSelector(selectSortWages);
  const currentFilters = useSelector(selectSearchFilters);
  const searchId = useSelector(selectCurrentSearchId);
  const loadedFilters = useSelector(selectLoadedFilters);
  const expirationDate = useSelector(selectUser).expirationDate;
  const location = useLocation();
  const navigate = useNavigate();
  const isUpdate = areEqual(currentFilters, loadedFilters);
  const keyword = currentFilters.keyword;
  const currentFiltersPath = location.pathname;

  useEffect(() => {
    if (!keyword) {
      navigate(currentFiltersPath.replace("sort", "filters"));
    }
  }, [location, navigate, keyword, currentFiltersPath]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateSort({ [name]: value }));
    const thumb = document.querySelector(`input[name="${name}"]`);
    thumb.setAttribute("data-value", value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const snakeCaseFilters = camelToSnake(currentFilters);
    const { keyword, city, sites, ...queries } = snakeCaseFilters;
    let request = {};
    if (!isUpdate) {
      request = {
        keyword: keyword,
        city: city,
        sites: sites,
        queries: queries,
        sort_wages: sortWages,
        subscriptionExpires: expirationDate,
      };
      dispatch(createNewSearch(request));
      navigate("/");
    } else {
      request = {
        searchId: searchId,
        sortWages: sortWages,
      };
      dispatch(updateSearch(request));
      navigate(currentFiltersPath.replace("sort", "candidates"));
    }
  };

  return (
    <div className={css.sortContainer}>
      <h1 className={css.title}>Sorting</h1>
      <p className={css.description}>
        Use the sliders to gauge the importance of each criteria. This will help
        us prioritize candidates that match your preferences.
      </p>
      <form onSubmit={submitHandler}>
        {sortKeys.map((key) => (
          <div key={key} className={css.sortItemContainer}>
            <label htmlFor={key} className={css.sortLabel}>
              <span className={css.sortLabelText}>
                {key === "completeness"
                  ? "Completeness of Resume Filling"
                  : key === "salary"
                  ? "Salary Match"
                  : key === "seniority"
                  ? "Experience Match"
                  : key === "keywords"
                  ? "Keywords Match"
                  : "Level of English Match"}
              </span>
              <div className={css.rangeContainer}>
                <input
                  type="range"
                  id={key}
                  name={key}
                  min="0"
                  max="1"
                  step="0.01"
                  value={sortWages[key]}
                  onChange={handleChange}
                  className={css.sortRange}
                />
                <span
                  className={css.itemValue}
                  style={{ left: `${sortWages[key] * 100 - 1}%` }}
                >
                  {sortWages[key]}
                </span>
              </div>
            </label>
            <span className={css.importance}>Very important</span>
          </div>
        ))}

        {!isUpdate && (
          <button type="submit" className={css.submitButton}>
            Run New Search
          </button>
        )}
        {isUpdate && (
          <button type="submit" className={css.submitButton}>
            Update sorting
          </button>
        )}
      </form>
    </div>
  );
};
