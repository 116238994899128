import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth, registerConfig } from "../auth/authApi";
import { refreshAccessToken } from "../auth/authOperations";
import { dispatchError } from "../global/globalOperations";
import { setPending, setSuccessMessage } from "../global/glolbalSlice";
import { subscriptionIsValid } from "../../utils";

export const fetchSearches = createAsyncThunk(
  "cvs/fetchSearches",
  async (_, thunkAPI) => {
    const makeFetchSearchesRequest = async () => {
      const response = await auth.get("/search", registerConfig);
      thunkAPI.dispatch(setSuccessMessage());
      return response.data;
    };

    try {
      thunkAPI.dispatch(setPending());
      return await makeFetchSearchesRequest();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await thunkAPI.dispatch(refreshAccessToken());
          return await makeFetchSearchesRequest();
        } catch (refreshError) {
          return dispatchError(refreshError, thunkAPI);
        }
      }
      return dispatchError(error, thunkAPI);
    }
  }
);

export const createNewSearch = createAsyncThunk(
  "cvs/createSearch",
  async (searchData, thunkAPI) => {
    const makeCreateSearchRequest = async (searchData, expirationDate) => {
      const response = await auth.post("/search", searchData, registerConfig);
      thunkAPI.dispatch(
        setSuccessMessage(
          `New search for position ${searchData.keyword} successfully created!`
        )
      );
      const { id, user_id } = response.data;
      thunkAPI.dispatch(
        fetchCandidates({
          searchId: id,
          userId: user_id,
          expirationDate: expirationDate,
        })
      );
      return response.data;
    };
    const expirationDate = searchData.subscriptionExpires;
    try {
      thunkAPI.dispatch(setPending());
      if (!subscriptionIsValid(expirationDate)) {
        throw new Error("Your subscription has expired!");
      }
      delete searchData.subscriptionExpires;
      return await makeCreateSearchRequest(searchData, expirationDate);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await thunkAPI.dispatch(refreshAccessToken());
          return await makeCreateSearchRequest(searchData, expirationDate);
        } catch (refreshError) {
          return dispatchError(refreshError, thunkAPI);
        }
      }
      return dispatchError(error, thunkAPI);
    }
  }
);

export const getSearchById = createAsyncThunk(
  "cvs/getSearch",
  async (searchId, thunkAPI) => {
    const makeGetSearchByIdRequest = async (searchId) => {
      const response = await auth.get(`/search/${searchId}`, registerConfig);
      thunkAPI.dispatch(setSuccessMessage());
      return response.data;
    };

    try {
      thunkAPI.dispatch(setPending());
      return await makeGetSearchByIdRequest(searchId);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await thunkAPI.dispatch(refreshAccessToken());
          return await makeGetSearchByIdRequest(searchId);
        } catch (refreshError) {
          return dispatchError(refreshError, thunkAPI);
        }
      }
      return dispatchError(error, thunkAPI);
    }
  }
);

export const updateSearch = createAsyncThunk(
  "cvs/updateSearch",
  async (request, thunkAPI) => {
    const makeUpdateSearchRequest = async (request) => {
      const response = await auth.put(
        `/search/${request.searchId}`,
        request.sortWages,
        registerConfig
      );
      thunkAPI.dispatch(
        setSuccessMessage(`Your search is successfully updated!`)
      );
      return response.data;
    };

    try {
      thunkAPI.dispatch(setPending());
      return await makeUpdateSearchRequest(request);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await thunkAPI.dispatch(refreshAccessToken());
          return await makeUpdateSearchRequest(request);
        } catch (refreshError) {
          return dispatchError(refreshError, thunkAPI);
        }
      }
      return dispatchError(error, thunkAPI);
    }
  }
);

export const fetchCandidates = createAsyncThunk(
  "cvs/fetchCandidates",
  async ({ searchId, userId, expirationDate }, thunkAPI) => {
    const makeFetchCandidatesRequest = async (searchId) => {
      const response = await auth.post(`/search/${searchId}`, registerConfig);
      thunkAPI.dispatch(setSuccessMessage(`Your search is already running!`));
      thunkAPI.dispatch(fetchSearches());
      return response.data;
    };

    try {
      thunkAPI.dispatch(setPending());
      if (!subscriptionIsValid(expirationDate)) {
        throw new Error("Your subscription has expired!");
      }
      webSocketManage(userId, searchId, thunkAPI);
      return await makeFetchCandidatesRequest(searchId);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await thunkAPI.dispatch(refreshAccessToken());
          return await makeFetchCandidatesRequest(searchId);
        } catch (refreshError) {
          return dispatchError(refreshError, thunkAPI);
        }
      }
      return dispatchError(error, thunkAPI);
    }
  }
);

export const deleteSearchById = createAsyncThunk(
  "cvs/deleteSearch",
  async (searchId, thunkAPI) => {
    const makeDeleteSearchByIdRequest = async (searchId) => {
      await auth.delete(`/search/${searchId}`, registerConfig);
      thunkAPI.dispatch(setSuccessMessage(`Search is successfully deleted!`));
      return { searchId };
    };

    try {
      thunkAPI.dispatch(setPending());
      return await makeDeleteSearchByIdRequest(searchId);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await thunkAPI.dispatch(refreshAccessToken());
          return await makeDeleteSearchByIdRequest(searchId);
        } catch (refreshError) {
          return dispatchError(refreshError, thunkAPI);
        }
      }
      return dispatchError(error, thunkAPI);
    }
  }
);

export const toggleSearchById = createAsyncThunk(
  "cvs/archiveSearch",
  async ({ searchId, searchStatus }, thunkAPI) => {
    const makeArchiveSearchByIdRequest = async (searchId, searchStatus) => {
      await auth.put(`/search/archive/${searchId}`, registerConfig);
      if (searchStatus !== "archive")
        thunkAPI.dispatch(
          setSuccessMessage(`Search is successfully archived!`)
        );
      else
        thunkAPI.dispatch(
          setSuccessMessage(`Search is successfully activated!`)
        );
      return { searchId };
    };

    try {
      thunkAPI.dispatch(setPending());
      return await makeArchiveSearchByIdRequest(searchId, searchStatus);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await thunkAPI.dispatch(refreshAccessToken());
          return await makeArchiveSearchByIdRequest(searchId, searchStatus);
        } catch (refreshError) {
          return dispatchError(refreshError, thunkAPI);
        }
      }
      return dispatchError(error, thunkAPI);
    }
  }
);

const webSocketMap = {};

const webSocketManage = (userId, searchId, thunkAPI) => {
  // const BASE_URL =
  //   "wss://backend.jollymeadow-1e4097e5.polandcentral.azurecontainerapps.io";

  const BASE_URL = process.env.REACT_APP_WEBSOCKETS_URL;
  console.log("WS URL is: ", BASE_URL);

  const socket = new WebSocket(
    `${BASE_URL}/search/ws?userId=${userId}&searchId=${searchId}`
  );
  socket.onopen = () => {
    console.log("Connection opened for user  ", userId);
    webSocketMap[searchId] = socket;
  };
  socket.onmessage = (event) => {
    console.log("Received message from WebSocket:", event.data);
    if (event.data === "Search completed") {
      webSocketMap[searchId].close();
    }
  };
  socket.onclose = () => {
    console.log("Connection closed for search:  ", searchId);
    delete webSocketMap[searchId];
    thunkAPI.dispatch(fetchSearches());
    thunkAPI.dispatch(setSuccessMessage(`Search ${searchId} completed`));
  };
};
