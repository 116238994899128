import { useSelector } from "react-redux";

import "./App.css";
import { Home } from "./pages/Home";
import { Searches } from "./components/Searches/Searches";
import { Routes, Route, Navigate } from "react-router-dom";
import { Page404 } from "./pages/Page404/Page404";
import { Register } from "./components/Register/Register";
import { selectIsAuth } from "./redux/selectors";
import { LoginForm } from "./components/LoginForm/LoginForm";
import { ResetRequest } from "./components/ResetRequest/ResetRequest";
import { SearchDetails } from "./components/SearchDetails/SearchDetails";
import { ResetPassword } from "./components/ResetPassword/ResetPassword";
import { Profile } from "./components/Profile/Profile";
import { Privacy } from "./components/Privacy/Privacy";
import { Agreement } from "./components/Agreement/Agreement";
import { Payment } from "./components/Payment/Payment";
import { Confirm } from "./components/Confirm/Confirm";

export const PrivateRoute = ({ element, isAuth }) => {
  return isAuth ? element : <Navigate to="/login" />;
};

export const ProtectedRoute = ({ element, isAuth }) => {
  return isAuth ? <Navigate to="/" /> : element;
};

function App() {
  const isAuth = useSelector(selectIsAuth);

  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route
          index
          element={<PrivateRoute element={<Searches />} isAuth={isAuth} />}
        />
        <Route
          path="search/:searchId/*"
          element={<PrivateRoute element={<SearchDetails />} isAuth={isAuth} />}
        />

        <Route
          path="create/*"
          element={<PrivateRoute element={<SearchDetails />} isAuth={isAuth} />}
        />

        <Route
          path="login"
          element={<ProtectedRoute element={<LoginForm />} isAuth={isAuth} />}
        />
        <Route
          path="register"
          element={<ProtectedRoute element={<Register />} isAuth={isAuth} />}
        />
        <Route
          path="reset"
          element={
            <ProtectedRoute element={<ResetRequest />} isAuth={isAuth} />
          }
        />
        <Route
          path="reset/:token"
          element={
            <ProtectedRoute element={<ResetPassword />} isAuth={isAuth} />
          }
        />
        <Route
          path="confirm/"
          element={<PrivateRoute element={<Confirm />} isAuth={isAuth} />}
        />
        <Route
          path="profile"
          element={<PrivateRoute element={<Profile />} isAuth={isAuth} />}
        />
        <Route
          path="pricing"
          element={<PrivateRoute element={<Payment />} isAuth={isAuth} />}
        />
        <Route
          path="agreement"
          element={<PrivateRoute element={<Agreement />} isAuth={isAuth} />}
        />
        <Route
          path="privacy-policy"
          element={<PrivateRoute element={<Privacy />} isAuth={isAuth} />}
        />
        <Route
          path="password"
          element={<PrivateRoute element={<ResetPassword />} isAuth={isAuth} />}
        />
      </Route>

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default App;
