import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSearchId,
  selectLoadedFilters,
  selectUser,
} from "../../redux/selectors";
import { fetchCandidates } from "../../redux/cvs/cvsOperations";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import css from "./Candidates.module.css";
import { convertDateTime } from "../../utils";

export const Candidates = () => {
  const searchId = useSelector(selectCurrentSearchId);
  const userId = useSelector(selectUser).id;
  const expirationDate = useSelector(selectUser).expirationDate;
  const location = useLocation();
  const lastRun = useSelector(selectLoadedFilters).lastRun;
  const navigate = useNavigate();
  const currentFiltersPath = location.pathname;

  useEffect(() => {
    if (!searchId && currentFiltersPath.startsWith("/search")) {
      navigate(currentFiltersPath.replace("candidates", "filters"));
    }
  }, [location, navigate, searchId, currentFiltersPath]);

  const dispatch = useDispatch();
  const clickHandler = () => {
    dispatch(fetchCandidates({ searchId, userId, expirationDate }));
    navigate("/");
  };
  return (
    <div className={css.candidatesContainer}>
      <h1 className={css.title}>Candidates</h1>
      <div className={css.textButtonsContainer}>
        <div className={css.textContainer}>
          <h2 className={css.subTitle}>All candidates</h2>
          <p className={css.text}>Download a list of all your candidates.</p>
          <p className={css.text}>Last run:&nbsp; {convertDateTime(lastRun)}</p>
        </div>
        <div className={css.buttonContainer}>
          <button onClick={clickHandler} type="button" className={css.button}>
            <span>Run search</span>
          </button>
        </div>
      </div>
    </div>
  );
};
