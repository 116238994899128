import axios from "axios";
import qs from "qs";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ALLOW_ORIGIN = process.env.REACT_APP_ALLOW_ORIGIN;
const WS_BASE_URL = process.env.REACT_APP_WEBSOCKETS_URL;

console.log("BASE URL is: ", BASE_URL);
console.log("ALLOW ORIGIN is: ", ALLOW_ORIGIN);
console.log("WS BASE URL is: ", WS_BASE_URL);
console.log("Log for test both domains!");

export const auth = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const loginConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  transformRequest: [(data) => qs.stringify(data)],
};

export const registerConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const refreshConfig = {
  headers: {
    "Access-Control-Allow-Origin": ALLOW_ORIGIN,
  },
};

export const setAuthToken = (token) => {
  auth.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const clearAuthToken = () => {
  auth.defaults.headers.common.Authorization = "";
};
