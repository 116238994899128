import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createNewSearch,
  deleteSearchById,
  fetchCandidates,
  fetchSearches,
  getSearchById,
  toggleSearchById,
  updateSearch,
} from "./cvsOperations";
import { snakeToCamel } from "../../utils";

const initialState = {
  currentSearchId: null,
  searches: [],
  searchFilters: {
    keyword: "",
    city: "",
    lastRun: null,
    fullEmployment: "",
    minAge: 18,
    maxAge: 65,
    photo: false,
    noSalary: false,
    minSalary: 0,
    maxSalary: 200,
    education: false,
    seniority: Array(6).fill(false),
    english: "",
    gender: "",
    inOffice: "",
    keywords: "",
    sites: ["work.ua", "robota.ua"],
  },
  sortWages: {
    completeness: 0.5,
    seniority: 0.5,
    salary: 0.5,
    english: 0.5,
    keywords: 0.5,
  },
  loadedFilters: {},
};

const options = [
  fetchSearches,
  createNewSearch,
  getSearchById,
  updateSearch,
  fetchCandidates,
  deleteSearchById,
  toggleSearchById,
];

const getOption = (status) => options.map((option) => option[status]);

const handleRejected = (payload) => {
  console.log("Some error happened: ", payload);
};

const cvsSlice = createSlice({
  name: "cvs",
  initialState,
  reducers: {
    updateFilters: (state, { payload }) => {
      state.searchFilters = { ...state.searchFilters, ...payload };
    },
    updateSort: (state, { payload }) => {
      state.sortWages = { ...state.sortWages, ...payload };
    },
    resetFiltersAndSort: (state, _) => {
      state.searchFilters = { ...initialState.searchFilters };
      state.sortWages = { ...initialState.sortWages };
      state.loadedFilters = {};
      state.currentSearchId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearches.fulfilled, (state, { payload }) => {
        if (payload)
          state.searches = payload.map((search) => snakeToCamel(search));
      })
      .addCase(createNewSearch.fulfilled, (state, { payload }) => {
        state.searches.push(payload);
      })
      .addCase(getSearchById.fulfilled, (state, { payload }) => {
        state.currentSearchId = payload.id;
        state.searchFilters = snakeToCamel(payload.queries);
        state.searchFilters.keyword = payload.keyword ? payload.keyword : "";
        state.searchFilters.city = payload.city ? payload.city : "";
        state.searchFilters.sites = payload.sites;
        state.searchFilters.lastRun = payload.last_run ? payload.last_run : "";
        if (state.searchFilters.seniority.length === 5) {
          state.searchFilters.seniority.push(false);
        }

        state.loadedFilters = { ...state.searchFilters };
        state.sortWages = snakeToCamel(payload.sort_wages);
      })
      .addCase(updateSearch.fulfilled, (_state, { payload }) => {
        const { id } = payload;
        console.log(`Search ${id} updated`);
      })
      .addCase(fetchCandidates.fulfilled, (_state, { payload }) => {
        const { id } = payload;
        console.log(`Search ${id} running...`);
      })
      .addCase(deleteSearchById.fulfilled, (state, { payload }) => {
        const { searchId } = payload;
        state.searches = state.searches.filter(
          (search) => search.id !== searchId
        );
      })
      .addCase(toggleSearchById.fulfilled, (state, { payload }) => {
        const { searchId } = payload;
        const archivedSearch = state.searches.find(
          (search) => search.id === searchId
        );
        archivedSearch.status =
          archivedSearch.status === "archive" ? "ready" : "archive";
      })
      .addMatcher(isAnyOf(...getOption("rejected")), (_state, { payload }) =>
        handleRejected(payload)
      );
  },
});

export const cvsSliceReducer = cvsSlice.reducer;
export const { updateFilters, updateSort, resetFiltersAndSort } =
  cvsSlice.actions;
